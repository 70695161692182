import { buildAdaptiveContentAdapter } from '../../adapter/adaptiveContent/adaptiveContent.adapter';
import { ONE_SECOND_IN_MS } from '../../constants';
import { buildAdaptiveContentService } from '../../services/adaptiveContent/adaptiveContent.service';
import model from './model';

export default model.createController(
  ({ $bind, $widget, flowAPI, controllerConfig, $w, widgetConfig, $props }) => {
    return {
      pageReady: async () => {
        const { httpClient } = flowAPI;
        const { compId } = $props;

        const adaptiveContentAdapter = buildAdaptiveContentAdapter(httpClient);
        const adaptiveContentService = buildAdaptiveContentService(
          adaptiveContentAdapter,
        );

        if (
          flowAPI.environment.isSSR ||
          flowAPI.environment.isPreview ||
          flowAPI.environment.isViewer
        ) {
          const originalContent = $w('#text1').text;

          $w('#text1').text = await adaptiveContentService.getAdaptiveContent(
            compId,
            originalContent,
            flowAPI.environment.isSSR
              ? ONE_SECOND_IN_MS
              : ONE_SECOND_IN_MS * 10,
          );
        }
      },
      exports: {},
    };
  },
);
